var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "8",
      "md": "4"
    }
  }, [_c('v-row', {
    staticClass: "mt-10 mb-10",
    attrs: {
      "justify": "center"
    }
  }, [_c('h1', [_vm._v("Finner ikke siden/URL")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }